.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
}

.App-link {
  color: #61dafb;
}
